import React from 'react'
import coverImage from '../../images/cover-guidelines.jpg'
import CSS from './IntroSection.module.css'

/**
 * Intro section
 */

const IntroSection = () => {
  return (
    <IntroSectionTemplate
      preface={
        <>
          We <em>constantly find ways</em> to improve our processes and how
          people <em>work together</em>.
        </>
      }
      imageA={coverImage}
      heading={<>The Mashup Garage Playbook</>}
      body={
        <>
          <p>
            Our playbook is a collection of our learnings and experiences
            throughout the years. Having collectively done close to a hundred
            projects with startups and businesses around the world.
          </p>
          <p>
            The playbook keeps us consistent on our practices, technical
            competencies, management styles, and most of all, our culture.
          </p>
          <p>Constantly updated and improved by our team.</p>
          <p>
            <a className={CSS.introLink} href='/playbook'>
              Visit our Playbook
            </a>
          </p>
        </>
      }
    />
  )
}

/*
 * Props
 */

export interface TemplateProps {
  preface: React.ReactNode
  imageA: string
  heading: React.ReactNode
  body: React.ReactNode
}

/**
 * Intro section template
 */

const IntroSectionTemplate = (props: TemplateProps) => {
  const { preface, imageA, heading, body } = props

  return (
    <section className={CSS.root}>
      <div className={CSS.container}>
        <div className={CSS.prefaceContainer}>
          <div className={CSS.preface}>{preface}</div>
        </div>
        <div className={CSS.bodyContainer}>
          <div className={CSS.text}>
            <h1 className={CSS.sectionHeading}>{heading}</h1>
            <div className={CSS.sectionDescription}>{body}</div>
          </div>
          <div className={CSS.images}>
            <div className={CSS.image}>
              <img src={imageA} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

/**
 * Export
 */

export { IntroSectionTemplate }
export default IntroSection
