import React from 'react'
import CSS from './ServicesSection.module.css'

const ServicesSection = () => {
  return (
    <ServicesSectionTemplate
      heading='The way we work'
      description={
        <>
          <p>Let us figure things out for you.</p>
          <p>
            As experienced CTOs with more than a decade of experience, working
            with different industries has taught us valuable knowledge that has
            formed the foundation of Mashup Garage and its subsidiaries.
          </p>
        </>
      }
      services={
        <>
          <ServiceBlock
            heading='Our technology stack'
            subheading='Ruby on Rails, Elixir & Phoenix, and React'
            description={
              <>
                We learned throughout the years that for work to be done
                properly, an organization needs to focus on a few things and be
                really good at it.
                <p>
                  Repeatedly iterating and improving our processes and skills.
                </p>
                <p>
                  <a href='/playbook/tech.html'>
                    Check out our tech stack here
                  </a>
                </p>
              </>
            }
          />
          <ServiceBlock
            heading='Design process'
            subheading='Expectations management'
            description={
              <>
                <p>
                  We are firm believers of proper product planning. Our design
                  process allows us to effectively develop and design products.
                </p>
                <p>
                  This helps us understand the problem we are solving during
                  early stage of development and to provide a cohesive +
                  consistent plan for every team member.
                </p>
                <p>
                  <a href='/playbook/design_process.html'>
                    Read more about our design process here
                  </a>
                </p>
              </>
            }
          />
          <ServiceBlock
            heading='The sprint lifecycle'
            subheading='The agile way'
            description={
              <>
                <p>
                  Expectations are always hard to manage. Our Sprint Lifecycle
                  is a set of methodologies and principles that sets the
                  foundational framework of our development processes.
                </p>
                <p>
                  <a href='/playbook/agile.html'>
                    Read more about our design process here
                  </a>
                </p>
              </>
            }
          />
          <ServiceBlock
            heading='The Playbook'
            subheading='Learn more about us'
            description={
              <>
                Our playbook is a collection of our learnings and experiences
                throughout the years of working together.
                <p>
                  <a href='/playbook'>Visit our Playbook</a>
                </p>
              </>
            }
          />
        </>
      }
    />
  )
}

export interface TemplateProps {
  heading: React.ReactNode
  subheading?: React.ReactNode
  description: React.ReactNode
  services: React.ReactNode
}

const ServicesSectionTemplate = (props: TemplateProps) => {
  const { heading, description, services } = props
  return (
    <section className={CSS.root}>
      <div className={CSS.container}>
        <div className={CSS.sectionInfo}>
          <h1 className={CSS.sectionHeading}>{heading}</h1>
          <div className={CSS.sectionDescription}>{description}</div>
        </div>
        <div className={CSS.services}>{services}</div>
      </div>
    </section>
  )
}

export interface ServiceBlockProps {
  heading: React.ReactNode
  subheading: React.ReactNode
  description: React.ReactNode
}

const ServiceBlock = (props: ServiceBlockProps) => {
  const { heading, subheading, description } = props
  return (
    <div className={CSS.service}>
      <h2 className={CSS.serviceHeading}>{heading}</h2>
      <div className={CSS.serviceSubheading}>{subheading}</div>
      <div className={CSS.serviceDescription}>{description}</div>
    </div>
  )
}

export { ServicesSectionTemplate }
export { ServiceBlock }
export default ServicesSection
