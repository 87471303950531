import favicon from './images/favicon.ico'
import image from './images/meta/mashup-garage.jpg'

export default {
  default: {
    siteMetadata: {
      title:
        'Ruby on Rails, Elixir Phoenix, React, Software Developers | Manila, Philippines',
      description:
        'Mashup Garage is a product incubator that brings together talents from product development, software engineering and interaction design.',
      keywords:
        'ruby on rails, elixir, phoenix, react, software teams, software developers, software engineers',
      image,
      favicon
    }
  },
  agile: {
    siteMetadata: {
      title:
        'Agile Web Development | Ruby on Rails, Elixir Phoenix, React, Software Developers | Manila, Philippines',
      description:
        'Mashup Garage is a product incubator that brings together talents from product development, software engineering and interaction design.',
      keywords:
        'ruby on rails, elixir, phoenix, react, software teams, software developers, software engineers',
      image,
      favicon
    }
  }
}
