import React from 'react'
import Layout from '../Layout'

import Helmet from 'react-helmet'
import LargeFooterSection from '../../sections/LargeFooterSection'
import LocationSection from '../LocationSection'
import { NavArea, NavLayout } from '../Navigation'
import IntroSection from './IntroSection'
import ServicesSection from './ServicesSection'
import TitleSection from './TitleSection'

// TODO: These should be taken in via useStaticQuery()
import siteConfig from '../../site-config'

const siteMetadata = {
  title: siteConfig.agile.siteMetadata.title,
  meta: [
    { name: 'description', content: siteConfig.agile.siteMetadata.description },
    { name: 'keywords', content: siteConfig.agile.siteMetadata.keywords },
    {
      property: 'og:description',
      content: siteConfig.agile.siteMetadata.description
    },
    {
      name: 'twitter:description',
      content: siteConfig.agile.siteMetadata.description
    },
    { property: 'og:image', content: `${siteConfig.agile.siteMetadata.image}` },
    { name: 'twitter:image', content: 'summary_large_image' }
  ],
  link: [
    { rel: 'shortcut icon', href: `${siteConfig.default.siteMetadata.favicon}` }
  ]
}

const IndexPage = () => (
  <Layout>
    <Helmet
      title={siteMetadata.title}
      meta={siteMetadata.meta}
      link={siteMetadata.link}
    />

    <NavLayout>
      <NavArea dark>
        <TitleSection />
      </NavArea>

      <NavArea light>
        <IntroSection />
      </NavArea>

      <NavArea darkAndLight>
        <ServicesSection />
      </NavArea>

      <NavArea dark>
        <LocationSection />
        <LargeFooterSection />
      </NavArea>
    </NavLayout>
  </Layout>
)

export default IndexPage
